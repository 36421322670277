<template>
    <div class="h-100 position-relative">
        <img src="@/assets/img/home/home.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
        <div class="d-flex align-items-center carousel-image position-absolute text-white">
            <div>
                <h1>OurGift la mejor mesa de regalos</h1>
                <p>Que te permitirá vivir una experiencia diferente e inolvidable, recibirás los regalos que deseas, viajes y mucho más.   Todo en un solo lugar y sin costo!</p>
                <div class="d-flex flex-md-row flex-column">
                    <router-link :to="{name: 'mesas.create'}" class="btn btn-primary text-white btn-lg rounded-pill mb-3 me-0 me-md-3">
                        Crea tu mesa de regalo
                    </router-link>
                    <router-link :to="{name: 'mesas.index'}" class="btn btn-outline-light btn-lg rounded-pill mb-3">
                        Encuentra una mesa
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HomeCarousel'
}
</script>