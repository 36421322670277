<template>
  <div>
    <div class="mvh-100">
      <HomeCarousel />
    </div>
    <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
  </div>
</template>
<script>
  import HomeCarousel from '@/components/home/Carousel.vue'
  import HomeCard from '@/components/home/Card.vue'
  import Img1 from '@/assets/img/home/2.jpg'
  import Img1_2 from '@/assets/img/home/2-1.png'
  import Img1_3 from '@/assets/img/home/2-2.png'

  import Img2 from '@/assets/img/home/3.jpg'
  import Img2_2 from '@/assets/img/home/3-1.png'

  import Img3 from '@/assets/img/home/4.jpg'
  import Img3_2 from '@/assets/img/home/4-1.png'
  
  export default {
    name: 'Home',
    components: {
      HomeCarousel,
      HomeCard
    },
    data(){
      return {
        cards: [{
          title: 'MESA DE REGALOS ONLINE 100% PERSONALIZADA',
          descripcion: '¡Así es! Esta experiencia te da la libertad que necesitas para poder elegir dentro de una gran variedad de opciones, diseñar tu mesa de regalos con lo que quieres recibir y a su vez compartir con tus invitados para que ellos puedan escoger el regalo que deseen obsequiarte.  ¡Es la forma ideal y perfecta!<br><br>Entendemos lo complicado y costoso que es el proceso de planear un evento y hacer tu lista de regalos, por eso creamos esta solución all in one, sin costo para ti.  ¡Te lo mereces!',
          img1: Img1,
          img2: Img1_2,
          img3: Img1_3,
          bg: 'bg-primary text-white',
          align: 'end',
          url: 'como-funciona.index',
          urlname: 'Como funciona',
          urlClass: 'btn-light',
          hide: true
        },
        {
          title: 'Proveedores',
          descripcion: 'En OurGift podrás tener un mundo de posibilidades encontrando los mejores proveedores, productos y experiencias en un solo lugar, a un solo click de distancia.',
          img1: Img2,
          img2: Img2_2,
          bg: 'bg-secondary text-white',
          align: 'start',
          url: 'proveedores.index',
          urlname: 'Proveedores',
          urlClass: 'btn-light',
          hide: true,
        },
        {
          title: 'Productos',
          descripcion: 'OurGift es nuestra mesa de regalos online personalizada, en donde podrás elegir dentro de una gran variedad de proveedores, los productos que deseas, crear tus propias experiencias y tener un evento inolvidable.   Con OurGift no tienes que depender de una tienda departamental para disfrutar de buenos obsequios, ni quedarte con las ganas de regalos que no encontraste en la tienda que escogiste.  Ya no será necesario que los invitados llenen tu casa con regalos que no utilizarás.',
          img1: Img3,
          img2: Img3_2,
          bg: 'bg-light text-dark',
          align: 'end',
          url: 'productos.index',
          urlname: 'Productos',
          urlClass: 'btn-primary',
          hide: true
        }]
      }
    },
    mounted(){
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted(){
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll () {
        for (let index = 0; index < this.cards.length; index++) {
          var top = document.getElementById('home-card-'+index).offsetTop
          if(document.documentElement.scrollTop >= (top-20)){
            if(this.cards[index]['hide']){
              this.cards[index]['hide'] = false
            }
          }
        }
      }
    }
  }
</script>
